import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { reposApi } from '../../api/repos.api';
import { getRepos } from '../../model/getRepos';

const getInitialRepos = async (
  httpClient: ControllerParams['flowAPI']['httpClient'],
) => {
  try {
    return await getRepos(httpClient, 1);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const createController: CreateControllerFn = async ({ flowAPI }) => {
  const { isPreview } = flowAPI.environment;

  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
  return {
    async pageReady() {
      flowAPI.controllerConfig.setProps({
        repos: await getInitialRepos(flowAPI.httpClient),
        getRepos: (page: number) => getRepos(flowAPI.httpClient, page),
        message: isPreview ? 'Hey hey!' : 'Hey!',
        submitStar: async () => {
          const { data } = await flowAPI.httpClient.request(reposApi(2));
          return data;
        },
      });
    },
  };
};

export default createController;
