import { IHttpClient } from '@wix/yoshi-flow-editor';
import { reposApi } from '../api/repos.api';

export const getRepos = async (httpClient: IHttpClient, page: number) => {
  const { data } = await httpClient.request(reposApi(page));
  if (!Array.isArray(data?.repos)) {
    throw new Error(
      `Invalid payload structure from repos API, expected Array but received: ${JSON.stringify(
        data,
      )}`,
    );
  }
  return data.repos;
};
